








































































import { Route } from 'vue-router'
import { Component, Vue } from 'vue-property-decorator'
import { documentsModule, stateModule } from '@/store/index'

import { DocumentResource } from '@/models/documents/DocumentResource'
import { CommentCreateRequest } from '@/requests/comments/CommentCreateRequest'
import { DocumentPatchRequest } from '@/requests/documents/DocumentPatchRequest'

import DocumentModal from '@/components/modals/Document.vue'

@Component({
  components: {
    DocumentModal
  }
})
export default class DocumentDetailIndex extends Vue {

  private showRelevantDropdown: boolean = false
  private relevanceStatuses: string[] = ['Relevant', 'NotRelevant', 'Unmarked']
  private showModal: boolean = false

  private get detail(): DocumentResource | null {
    return documentsModule.detail
  }

  private get canChangeRelevance(): boolean {
    return this.detail ? this.detail.canPerformAction('can_change_relevance') : false
  }

  private get canBookmark(): boolean {
    return this.detail ? this.detail.canPerformAction('can_bookmark') : false
  }

  private get relevantButtonClasses(): { [key: string]: boolean } | null {
    if (this.detail) {
      return {
        approve: this.detail.relevance === 'relevant' || this.detail.relevance === 'Relevant',
        decline: this.detail.relevance === 'irrelevant' || this.detail.relevance === 'NotRelevant' || this.detail.relevance === 'Not Relevant',
        grey: this.detail.relevance === 'unmarked' || this.detail.relevance === 'Unmarked',
        open: this.showRelevantDropdown
      }
    }
    return null
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      const ID = parseInt(to.params.document_id, 10)
      await documentsModule.getById(ID)
      next()
    } catch (e) {
      next({ name: 'public-documents-overview' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    if (documentsModule.detail && parseInt(to.params.document_id, 10) === documentsModule.detail.id) {
      next()
    } else {
      // Different document so get new one
      stateModule.setLoading(true)
      try {
        const ID = parseInt(to.params.document_id, 10)
        await documentsModule.getById(ID)
        next()
      } catch (e) {
        next(from.path)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private setStatus(status: string): void {
    if (this.detail) {
      this.patch({ relevance: status })
    }
  }

  private bookmark(): void {
    if (this.detail) {
      this.patch({ bookmarked: !this.detail.bookmarked })
    }
  }

  private async patch(body: DocumentPatchRequest): Promise<void> {
    if (this.detail) {
      stateModule.setLoading(true)
      try {
        await this.detail.patch(body)
        documentsModule.get()
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async comment(body: CommentCreateRequest): Promise<void> {
    if (!this.detail) {
      return
    }
    stateModule.setLoading(true)


    try {
      await this.detail.postComment(new CommentCreateRequest({ ...body }))
    } finally {
      stateModule.setLoading(false)
    }
  }

  private goBack(): void {
    this.$router.push({ name: 'public-documents-overview' })
  }

  private toggleDocumentInModal(): void {
    this.showModal = !this.showModal
  }

  private toggleRelevant(): void {
    this.showRelevantDropdown = !this.showRelevantDropdown
  }

}
